const API_URL = process.env.VUE_APP_API_URL
const API_VERSION = process.env.VUE_APP_API_VERSION

const API_ROUTES = {
	medicine: {
		get: `${API_URL}/${API_VERSION}/medicine`,
		save: `${API_URL}/${API_VERSION}/medicine`,
		delete: `${API_URL}/${API_VERSION}/medicine`,
		update: `${API_URL}/${API_VERSION}/medicine`,
	},
	medicinePresentation: {
		get: `${API_URL}/${API_VERSION}/medicine/presentation`,
	},
	medicineColor: {
		get: `${API_URL}/${API_VERSION}/medicine/colors`,
		save: `${API_URL}/${API_VERSION}/medicine/colors`,
		delete: `${API_URL}/${API_VERSION}/medicine/colors`,
		update: `${API_URL}/${API_VERSION}/medicine/colors`,
	},
	exam: {
		get: `${API_URL}/${API_VERSION}/exam`,
		save: `${API_URL}/${API_VERSION}/exam`,
		delete: `${API_URL}/${API_VERSION}/exam`,
		update: `${API_URL}/${API_VERSION}/exam`,
	},
	entity: {
		get: `${API_URL}/${API_VERSION}/eps`,
		save: `${API_URL}/${API_VERSION}/entity`,
		delete: `${API_URL}/${API_VERSION}/entity`,
		update: `${API_URL}/${API_VERSION}/entity`,
	},
	specialty: {
		get: `${API_URL}/${API_VERSION}/specialties`,
		save: `${API_URL}/${API_VERSION}/specialties`,
		delete: `${API_URL}/${API_VERSION}/specialties`,
		update: `${API_URL}/${API_VERSION}/specialties`,
	},
	dependency: {
		get: `${API_URL}/${API_VERSION}/dependencies`,
	},
	eps: {
		get: `${API_URL}/${API_VERSION}/eps`,
		save: `${API_URL}/${API_VERSION}/eps`,
		delete: `${API_URL}/${API_VERSION}/eps`,
		update: `${API_URL}/${API_VERSION}/eps`,
	},
	patient: {
		get: `${API_URL}/${API_VERSION}/patients`,
		find: `${API_URL}/${API_VERSION}/patients`,
		save: `${API_URL}/${API_VERSION}/patients`,
		delete: `${API_URL}/${API_VERSION}/patients`,
		update: `${API_URL}/${API_VERSION}/patients`,
	},
	risk: {
		get: `${API_URL}/${API_VERSION}/risks`,
		save: `${API_URL}/${API_VERSION}/risks`,
		delete: `${API_URL}/${API_VERSION}/risks`,
		update: `${API_URL}/${API_VERSION}/risks`,
	},
	brand: {
		get: `${API_URL}/${API_VERSION}/company/:customer_id/brands`,
	},
	service: {
		get: `${API_URL}/${API_VERSION}/company/brand/:brand_id/service`,
	},
	area: {
		get: `${API_URL}/${API_VERSION}/company/filters/area`,
	},
	subarea: {
		get: `${API_URL}/${API_VERSION}/company/area/:area_id/subarea`,
	},
	locationPatient: {
		get: `${API_URL}/${API_VERSION}/company/filters/sub-area/:area_id`,
		save: `${API_URL}/${API_VERSION}/company/location-patient`,
	},
	movePatientLocation: {
		update: `${API_URL}/${API_VERSION}/company/location-patient/:id/move/:new_idt`,
	},
	altaInfo: {
		update: `${API_URL}/${API_VERSION}/company/location-patient/:id/departure-info`,
	},
	altaFisico: {
		update: `${API_URL}/${API_VERSION}/company/location-patient/:id/departure`,
	},
	altaCancel: {
		update: `${API_URL}/${API_VERSION}/company/location-patient/:id/cancel-departure`,
	},
	employee: {
		get: `${API_URL}/${API_VERSION}/employee`,
	},
	typeService: {
		get: `${API_URL}/${API_VERSION}/maintenance-types`,
	},
	maintenanceService: {
		save: `${API_URL}/${API_VERSION}/maintenance-services`,
	},
	assignExamPatient: {
		get: `${API_URL}/${API_VERSION}/exam-patient`,
		save: `${API_URL}/${API_VERSION}/exam-patient`,
		delete: `${API_URL}/${API_VERSION}/exam-patient/:id/patient/:patientId`,
		update: `${API_URL}/${API_VERSION}/exam-patient`,
	},
	assignMedicinePatient: {
		get: `${API_URL}/${API_VERSION}/patient-medicine`,
		save: `${API_URL}/${API_VERSION}/patient-medicine`,
		delete: `${API_URL}/${API_VERSION}/patient-medicine`,
		update: `${API_URL}/${API_VERSION}/patient-medicine`,
	},
	order: {
		get: `${API_URL}/${API_VERSION}/menu-order`,
	},
	examByDate: {
		get: `${API_URL}/${API_VERSION}/exam-patient/customer/filter`,
	},
	call: {
		get: `${API_URL}/${API_VERSION}/company/filters/area`,
		statics: `${API_URL}/api/getAllCallStatus`,
		data: `${API_URL}/api/getCallData`,
	},
}

export { API_ROUTES }
